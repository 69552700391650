export const GameEnum = {
    MTG: "mtg",
    LORCANA: "lorcana",
};

export const GameEnumValues = {
    [GameEnum.LORCANA]: "Lorcana",
    [GameEnum.MTG]: "Magic",
};

export const GameEnumTitles = {
    [GameEnum.LORCANA]: "Disney Lorcana",
    [GameEnum.MTG]: "Magic: The Gathering",
};

// gamelinks
export const GameEnumLinks = {
    LORCANA: {
        BASE: "/lorcana",
        DECKS: "/lorcana/decks",
        SETS: "/lorcana/sets",
        POSTS: "/lorcana/posts",
        CARDS: "/lorcana/kaarten",
    },
    MTG: {
        BASE: "/mtg",
        DECKS: "/mtg/decks",
        SETS: "/mtg/sets",
        POSTS: "/mtg/posts",
        CARDS: "/mtg/kaarten",
    },
};

type GamePath = "BASE" | "DECKS" | "SETS" | "POSTS" | "CARDS";

export function getGameLink(game: string, path: GamePath): string {
    const uppercaseGame = game.toUpperCase() as keyof typeof GameEnumLinks;
    const gameLinks = GameEnumLinks[uppercaseGame];
    if (!gameLinks) {
        throw new Error(`Invalid game slug: ${game}`);
    }

    const link = gameLinks[path];
    if (!link) {
        throw new Error(`Invalid path: ${path} for game: ${game}`);
    }

    return link;
}
